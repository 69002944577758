import React from 'react';
import styled from 'styled-components';

import { ILabelValue, ILabelValueAsChildren } from '@/types/field';

import { Flex } from '@/components/designSystem/Layout';
import { BodyText } from '@/components/designSystem/Typography';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';

const StyledValue = styled(BodyText)`
  word-wrap: break-word;
`;

const StyledValueAsChildren = styled(BodyText)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LabeledValue = ({ label, value }: ILabelValue) => (
  <Flex direction='column'>
    <FieldLabel>{label}</FieldLabel>
    <StyledValue>{value}</StyledValue>
  </Flex>
);

export const LabeledValueRow = ({ label, value }: ILabelValue) => (
  <Flex gap="0.5rem" align="center">
    <FieldLabel>{label} :</FieldLabel>
    <StyledValue>{value || "Not Provided"}</StyledValue>
  </Flex>
);

export const LabeledValueAsChildren = ({label, children}: ILabelValueAsChildren) => (
  <Flex direction='column'>
    <FieldLabel>{label}</FieldLabel>
    <StyledValueAsChildren>{children}</StyledValueAsChildren>
  </Flex>

);

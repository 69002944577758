import React from 'react';
import { ReactComponent as Clipboard } from '@/assets/navBarIcons/clipboard.svg';
import { ReactComponent as Exit } from '@/assets/navBarIcons/exit.svg';
import { ReactComponent as Gear } from '@/assets/navBarIcons/gear.svg';
import { ReactComponent as Template } from '@/assets/navBarIcons/template.svg';
import { ReactComponent as Review } from '@/assets/navBarIcons/review.svg';
import { ReactComponent as Internal } from '@/assets/navBarIcons/internal.svg';
import { ReactComponent as Lifesaver } from '@/assets/navBarIcons/lifesaver.svg';
import { ReactComponent as Sliders } from '@/assets/navBarIcons/sliders.svg';
import { ReactComponent as Thumbsup } from '@/assets/navBarIcons/thumbsup.svg';
import { ReactComponent as People } from '@/assets/navBarIcons/twopeople.svg';
import { ReactComponent as PendingReview } from '@/assets/navBarIcons/pendingreview.svg';
import { ReactComponent as Approved } from '@/assets/navBarIcons/approved.svg';
import { ReactComponent as Imported } from '@/assets/navBarIcons/imported.svg';
import { ReactComponent as PendingApproval } from '@/assets/navBarIcons/pendingapproval.svg';
import { ReactComponent as InProgress } from '@/assets/navBarIcons/inprogress.svg';
import { ReactComponent as Archived } from '@/assets/navBarIcons/archived.svg';
import { HiTemplate } from "react-icons/hi";
import { HiCog8Tooth, HiClipboardDocumentList } from "react-icons/hi2";
import { NAV_ITEMS } from '../../../constants';

interface IProps {
  title: string,
}

export const NavBarIcon = ({ title }: IProps) => {
  switch (title) {
  case NAV_ITEMS.CUSTOMERS:
    return <People />;
  case NAV_ITEMS.APPLICATIONS:
    return <Clipboard />;
  case NAV_ITEMS.REFERENCES:
    return <Thumbsup />;
  case NAV_ITEMS.TEMPLATE:
    return <Template />;
  case NAV_ITEMS.APP_TEMPLATE:
    return <Gear />;
  case NAV_ITEMS.REVIEW_TEMPLATE:
    return <Review />;
  case NAV_ITEMS.INTERNAL_TEMPLATE:
    return <Internal />;
  case NAV_ITEMS.SETTINGS:
    return <Sliders />;
  //case NAV_ITEMS.HELP:
  //  return <Lifesaver />;
  case NAV_ITEMS.LOGOUT:
    return <Exit />;
  case NAV_ITEMS.PENDING_REVIEW:
    return <PendingReview />;
  case NAV_ITEMS.APPROVED:
    return <Approved />;
  case NAV_ITEMS.IMPORTED:
    return <Imported />;
    case NAV_ITEMS.PENDING_APPROVAL:
      return <PendingApproval />;
    case NAV_ITEMS.IN_PROGRESS:
      return <InProgress />;
    case NAV_ITEMS.ARCHIVED:
      return <Archived />;
  default:
    return null;
  }
};

import React, { Dispatch, SetStateAction } from 'react';

import { getOr } from 'lodash/fp';
import { ICustomer } from '@/types/customer';
import { IStakeholder } from '@/types/stakeholder';
import { ICustomField } from '@/types/customField';

import { SORTED_COUNTRIES } from '@/constants';

import { Button } from '@/components/designSystem/buttons';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { DatePickerField } from '@/components/designSystem/Form/DatePickerField';
import { Card } from '@/components/designSystem/cards/Card';

import { StyledButtonsContainer } from '../../../../styled';
import { StyledContactsForm } from '../styled';
import { is } from 'date-fns/locale';

interface IProps {
  stakeholders: IStakeholder[],
  providerCustomContactFields: ICustomField[],
  customerData: ICustomer,
  setEditActive: Dispatch<SetStateAction<boolean>>,
}

export const ContactsSectionForm = ({ stakeholders, providerCustomContactFields, customerData, setEditActive }: IProps) => {
  const companySettings = getOr([], 'providerCompany.settings', customerData);
  const isManualCustomer = getOr(false, 'isManualCustomer', customerData);

  return (
    <StyledContactsForm>
      {stakeholders.map((stakeholder: IStakeholder, index) => {
        const customFieldContactInformation = stakeholder.customFieldId
          ? providerCustomContactFields.find((customField) => customField.id === stakeholder.customFieldId)?.contact
          : null;

        return (
          <Card key={stakeholder.id}>
            <Row>
              <TextField
                required={!!(stakeholder.isOwner ?? stakeholder.isPrimaryContact)}
                name={`stakeholder${index}-position`}
                disabled={!!stakeholder.customFieldId}
                type='text'
                label='Position'
              />
              <TextField
                required={!!(stakeholder.isOwner || stakeholder.isPrimaryContact || customFieldContactInformation?.nameRequired)}
                name={`stakeholder${index}-name`}
                type='text'
                label='Name'
              />
            </Row>
            <Row>
              <TextField
                name={`stakeholder${index}-email`}
                disabled={!isManualCustomer}
                type='text'
                label='Email'
              />
              <Row>
                <PhoneNumberField
                  required={!!(stakeholder.isOwner || stakeholder.isPrimaryContact || customFieldContactInformation?.phoneRequired)}
                  name={`stakeholder${index}-phoneNumber`}
                  label='Phone number'
                  placeholder='Phone number'
                />
                <TextField name={`stakeholder${index}-extension`} type='text' label='Extension' />
              </Row>
            </Row>
            {(stakeholder.isOwner && companySettings.enableAddress) ?
              (
                <>
                  <Row>
                    <TextField
                      required={companySettings.requireAddress}
                      name={`stakeholder${index}-addressLine1`}
                      type='text'
                      label='Home address'
                      placeholder='123 XYZ st.' />
                    <TextField
                      required={companySettings.requireAddress}
                      name={`stakeholder${index}-addressLine2`}
                      type='text'
                      label='Address line 2'
                      placeholder='123 XYZ st.' />
                  </Row>
                  <Row>
                    <TextField
                      required={companySettings.requireAddress}
                      name={`stakeholder${index}-state`}
                      type='text'
                      label='State / Province'
                      placeholder='New York' />
                    <TextField
                      required={companySettings.requireAddress}
                      name={`stakeholder${index}-postalCode`}
                      type='text'
                      label='Zip / Postal Code'
                      placeholder='90283' />
                  </Row>
                  <Row>
                    <TextField
                      required={companySettings.requireAddress}
                      name={`stakeholder${index}-city`}
                      type='text'
                      label='City'
                      placeholder='New York' />
                    <SelectField
                      required={companySettings.requireAddress}
                      name={`stakeholder${index}-country`}
                      label='Country'
                      options={SORTED_COUNTRIES} />
                  </Row>
                </>
              )
              : false
            }
            <Row>
              {(stakeholder.isOwner && companySettings.enableSsnSinNumber) ?
                (
                  <TextField
                    required={companySettings.requireSsnSinNumber}
                    type='text'
                    name={`stakeholder${index}-ssnSinNumber`}
                    label='SSN/SIN Number'
                    placeholder=''
                  />
                )
                : false
              }
              {(stakeholder.isOwner && companySettings.enableDriverLicenseNo) ?
                (
                  <TextField
                    required={companySettings.requireDriverLicenseNo}
                    type='text'
                    name={`stakeholder${index}-driverLicenseNo`}
                    label="Driver's License Number"
                    placeholder='' />
                )
                : false
              }
            </Row>
            <Row>
              {(stakeholder.isOwner && companySettings.enableDriverLicenseState) ?
                (
                  <TextField
                    required={companySettings.requireDriverLicenseState}
                    type='text'
                    name={`stakeholder${index}-driverLicenseState`}
                    label="Driver's License State/Province"
                    placeholder=''
                  />
                )
                : false
              }
              {(stakeholder.isOwner && companySettings.enableDateOfBirth) ?
                (
                  <DatePickerField
                    required={companySettings.requireDateOfBirth}
                    name={`stakeholder${index}-dob`}
                    label='Date Of Birth'
                  />
                )
                : false
              }
            </Row>
          </Card>
        )})}
      <StyledButtonsContainer>
        <Button onClick={() => setEditActive(false)} secondary>Cancel</Button>
        <Button type='submit' primary>Save</Button>
      </StyledButtonsContainer>
    </StyledContactsForm>
  )
};

import React, { useState } from 'react';

import { SectionContainer } from '../../../SectionContainer';
import { ContactsSectionContent } from './content';
import { ICustomer } from '@/types/customer';

interface IProps {
  canSeeEditControl: boolean,
  customerData: ICustomer,
  refetch: () => void,
}

export const ContactsSection = ({ canSeeEditControl, customerData, refetch }: IProps) => {
  const [isEditActive, setEditActive] = useState(false);

  return (
    <SectionContainer
      canSeeEditControl= {canSeeEditControl}// {canSeeEditControl} hiding form all CPs
      title='Contacts'
      isEditEnabled={!isEditActive}
      editSection={() => setEditActive(true)}
    >
      <ContactsSectionContent customerData={customerData} isEditActive={isEditActive} setEditActive={setEditActive} refetch={refetch} />
    </SectionContainer>
  )
};

import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { GET_OWN_CUSTOM_FIELDS } from '@/graphql/queries/getOwnCustomFields';

import { getOr } from 'lodash/fp';

import { BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { AddCustomFieldModal } from '@/modals/AddCustomField';

import { CustomFieldItem } from './CustomFieldItem';
import { StyledContainer, StyledCustomFieldsContainer } from './styled';
import { ICustomField } from '@/types/customField';

export const CreditApplicationCustomizableImportFields = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { data, refetch } = useQuery(GET_OWN_CUSTOM_FIELDS, {variables: {kind: "import"}});

  const customFields = getOr([], 'listCustomFields', data);

  return (
    <StyledContainer>
      <BodyText>
        Add the customizable fields for additional information you want to import about your customers.
      </BodyText>
      <StyledCustomFieldsContainer>
        {
          customFields.map((customField: ICustomField) => (
            <CustomFieldItem key={customField.id} parentKey='customFields' {...customField} refetch={refetch} />
          ))
        }
      </StyledCustomFieldsContainer>
      <Button primary onClick={() => setIsOpen(true)}>Add Field</Button>
      <AddCustomFieldModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetch} kind="IMPORT" />
    </StyledContainer>
  );
};

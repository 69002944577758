import gql from 'graphql-tag';

export const CUSTOMER_FRAGMENT = gql`
  fragment CustomerFragment on Customer {
    id
    currencyCode
    customerSince
    onboardComplete
    onboardStep
    bankVerificationStatus
    isManualCustomer
    scheduleDate
    creditReports {
      reportSource
      reportType
      storedFile {
        id
        path
        originalFilename
        currentFilename
        signedUrl
        insertedAt
        description
      }
    }

    providerCompany {
      id
      name
      agreementText
      numRequiredReferences
      numTotalReferences
      customFields {
        id
        type
        kind
        label
        description
        required
        orderIndex
        hideFromUi
        allowMultipleUploads
        options
        contact {
          showName
          nameRequired
          showEmail
          emailRequired
          showPhone
          phoneRequired
          positionRequired
          showExtension
          extensionRequired
        }
        yesToggle {
          type
          allowMultipleUploads
        }
        noToggle {
          type
          allowMultipleUploads
        }
      }
      vrCustomFields {
        id
        type
        label
        step
        name
        required
        enabled
        updatedAt
      }
      brand {
        id
        logo {
          id
          path
          originalFilename
          signedUrl
        }
        primaryColor
        secondaryColor
        brandColor
        backgroundColor
      }
      settings {
        autoBavDefault
        seekerApprovalAlert
        seekerDenialAlert
        requireEinFederalTaxNumber
        requirePersonalGuaranteeDefault
        enableAddress
        requireAddress
        enableAddressFor
        enableSsnSinNumber
        requireSsnSinNumber
        enableSsnSinNumberFor
        enableDriverLicenseNo
        requireDriverLicenseNo
        enableDriverLicenseNoFor
        enableDriverLicenseState
        requireDriverLicenseState
        enableDriverLicenseStateFor
        enableDateOfBirth
        requireDateOfBirth
        enableDateOfBirthFor
        enableShippingAddress
        enableBillingAddress
        requireShippingAddress
        requireBillingAddress
      }
    }

    settings {
      autoBav
      enablePersonalGuarantee
      requirePersonalGuarantee
      requireBankInfo
      updatedAt
    }

    customFieldAnswers {
      id
      text
      isToggled
      currency {
        currency
        amount
      }
      selections
      file {
        id
        originalFilename
        signedUrl
      }
      customField {
        id
        type
        label
        description
        required
        allowMultipleUploads
        options
        contact {
          showName
          nameRequired
          showEmail
          emailRequired
          showPhone
          phoneRequired
          positionRequired
          showExtension
          extensionRequired
        }
        yesToggle {
          type
          allowMultipleUploads
          description
        }
        noToggle {
          type
          allowMultipleUploads
          description
        }
        kind
      }
    }

    manualAccountNotes {
      id
      note
      insertedAt
      updatedAt
      account {
        id
      }
    }

    seekerCompany {
      id
      address {
        address_line_1
        address_line_2
        city
        state
        country
        postal_code
      }
      description
      established
      name
      phoneNumber
      taxId
      type
      website
      fullAddress
      streetAddressCity

      openCorporatesComparison {
        name
        address
        established
      }

      openCorporatesCount

      openCorporates {
        jurisdictionCode
        incorporationDate
        dissolutionDate
        companyType
        active
        currentStatus
        name
        publisher
        publisherUrl
        registeredAddress
        lastUpdatedDatetime
        streetAddressCity
      }

      stakeholders {
        id
        name
        position
        label
        email
        phoneNumber
        extension
        customFieldId
        ownershipPercentage
        isPrimaryContact
        isOwner
        address {
          address_line_1
          address_line_2
          city
          state
          country
          postal_code
        }
        ssnSinNumber
        driverLicenseNo
        driverLicenseState
        dob
        showNonBusinessEmailDomainWarning
      }

      pendingVendorInvites {
        id
        toEmail
        toName
        toCompanyName
        toPosition
        toPhoneNumber
        sent
        bounced
        type
        showNonBusinessEmailDomainWarning
        updatedAt
        optOut
        optOutAt
      }

      receivedReferences {
        id
        updatedAt

        customerSince

        lastPaymentOn
        lastPaymentAmount

        averageDaysToPay
        creditLimit
        creditTerms
        creditBalanceHigh
        creditBalancePastDue
        address
        websiteAddress
        accountNumber
        currentBalance
        comments
        emailThreads{
          messageId
          subject
          replyTo
          deliveredAt
          htmlBody
          textBody
          fromFull{
            mailboxHash
            email
            name
          }
          toFull{
            mailboxHash
            email
            name
          }
          ccFull{
            mailboxHash
            email
            name
          }
          bccFull{
            mailboxHash
            email
            name
          }
          attachments{
            name
            contentType
            contentLength
          }
        }
        attachments{
          id
          originalFilename
          currentFilename
          signedUrl
        }

        sourceCompany {
          id
          name
          stakeholders {
            id
            name
            email
            position
            phoneNumber
            extension
            customFieldId
            isPrimaryContact
          }
        }
        sourceUser {
          name
          email
          company
          phoneNumber
          showNonBusinessEmailDomainWarning
        }
      }

      manualInstitutions {
        id
        contactEmail
        contactName
        contactPhone
        contactTitle
        institutionAddress
        institutionName
        showNonBusinessEmailDomainWarning
        accounts {
          id
          isBusinessAccount
          firstname
          lastname
          accountNumber
          routingNumber
          accountType
          verificationStatus
          verificationMessage
          firstnameMatched
          lastnameMatched
          internalBankDocuments {
            id
            storedFile {
              id
              originalFilename
              signedUrl
            }
            notes
          }
          insertedAt
          updatedAt
        }
        bankStatements {
          id
          path
          originalFilename
          signedUrl
        }
      }

      finicityCustomers {
        id
        finicityAccounts {
          id
          accountDisplayNumber
          accountNumber
          currency
          finicityCreatedDate
          finicityLastUpdatedDate
          name
          status
          type
          institution {
            id
            name
            address {
              city
              state
              country
            }
          }
        }
      }

      finicityAccountProjections {
        id
        averageBalance
        highBalance
        lowBalance
        latestBalance
        latestBalanceDate
        balances {
          balance
          balanceDate
        }
        cashflowHistory {
          inflow
          outflow
          averageTransactionAmount
          numberOfTransactions
          startDate
          endDate
        }
        lastAccount {
          id
          accountDisplayNumber
          accountNumber
          currency
          finicityCreatedDate
          finicityLastUpdatedDate
          name
          status
          type
          institution {
            id
            name
            address {
              city
              state
              country
            }
          }
        }
      }
    }

    latestApplication {
      id
      assignedTo {
        id
      }
      assignedBy {
        id
      }
      assignmentNote
      creditLimit
      creditLimitCurrency
      requestedCreditLimit
      einFederalTaxNumber
      creditTerms
      providerComment
      reviewedAt
      creditProviderInternalComments {
        id
        comment
        user {
          id
          email
          name
        }
        insertedAt
        updatedAt
      }
      auditHistory {
        creditLimit
        creditTerms
        status
        providerComment
        updatedAt
      }
      dateApproved
      status
      providerDisplayStatus
      seekerDisplayStatus
      noBankInfo
      activeAgreement {
        id
        status
      }
      shippingAddress {
        address_line_1
        address_line_2
        city
        state
        country
        postal_code
      }
      billingAddress {
        address_line_1
        address_line_2
        city
        state
        country
        postal_code
      }
      documents {
        id
        originalFilename
        currentFilename
        author {
          name
        }
        signedUrl
        insertedAt
      }
    }
    serviceLocations {
      locationName
      address {
        address_line_1
        address_line_2
        city
        state
        country
        postal_code
      }
      phoneNumber
      email
      name
      position
    }
    signedAgreements {
      id
      status
      signedFile {
        id
        originalFilename
        signedUrl
        insertedAt
      }
    }
  }
`;

export const GET_CUSTOMER_AS_SEEKER = gql`
  query getCustomerAsSeeker($customerId: ID!) {
    getCustomerAsSeeker(customerId: $customerId) {
      ...CustomerFragment
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const GET_CUSTOMER_AS_PROVIDER = gql`
  query getCustomerAsProvider($customerId: ID!) {
    currentCompany {
      planInfo {
        enabledReview
      }
    }
    getCustomerAsProvider(customerId: $customerId) {
      ...CustomerFragment
    }
    getBankruptcies(customerId: $customerId) {
      count
      cases { 
        caseNo
        caseTitle
        courtCode
        chapter
        docket
        dateFiled
        dateOfLastFiling
      }
    }
    listPinnedActivities(customerId: $customerId) {
      id
      type
      actor
      data
      insertedAt
    }
    listActivities(customerId: $customerId) {
      id
      type
      actor
      data
      insertedAt
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

import React, { useState } from 'react';

import { Formik } from 'formik';

import { getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ICustomer } from '@/types/customer';
import { IProviderSettings } from '@/types/providerSettings';
import { NO_WEBSITE_VALUE } from '@/constants';

import { BodyText, H2 } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';

import { MdOutlineBusinessCenter, MdOutlineLocalShipping } from "react-icons/md";
import { CiFlag1, CiGlobe, CiPhone, CiReceipt } from "react-icons/ci";
import { GrMapLocation } from "react-icons/gr";

import { SectionContainer } from '../../../SectionContainer';
import { StyledGrayBox } from '../../../styled';
import {
  StyledCompanyInfoInformationColumn,
  StyledDescription,
  StyledIconLabel,
  StyledExternalLink
} from '../styled';
import { useInformationForm } from './useInformationForm';
import {
  buildAddressLine,
  formatBusinessVerificationMatchNumberToIcon,
} from '../functions';
import { EditInformationForm } from './EditInformationForm';
import { MutationFunction } from '@apollo/react-hooks';

interface IProps {
  customerData: ICustomer,
  refetch: () => void,
  providerUpdateCompany: MutationFunction,
  canSeeEditControl: boolean,
  providerSettings: IProviderSettings,
}

export const InformationSection = ({
  customerData, providerUpdateCompany, providerSettings, refetch, canSeeEditControl
}: IProps) => {
  const [isEditActive, setEditActive] = useState(false);

  const { initialValues, validation, handleSubmit } = useInformationForm(
    customerData, setEditActive, providerUpdateCompany, refetch
  );

  const billingAddress = customerData.latestApplication.billingAddress.address_line_1 ? customerData.latestApplication.billingAddress : null;
  const shippingAddress = customerData.latestApplication.shippingAddress.address_line_1 ? customerData.latestApplication.shippingAddress : null;
  const fullAddress = getOr(buildAddressLine(initialValues), 'seekerCompany.fullAddress', customerData);
  const businessVerificationComparison = getOr(
    {
      name: -1,
      address: -1,
      established: -1,
    },
    'seekerCompany.openCorporatesComparison',
    customerData
  );

  return (
    <SectionContainer
      title='Information'
      isEditEnabled={!isEditActive}
      canSeeEditControl={false} //{canSeeEditControl} //we are hiding edit from CPs
      editSection={() => setEditActive(true)}
      onCloseClick={() => setEditActive(false)}
    >
      <StyledGrayBox>
        {isEditActive && canSeeEditControl ? (
          <Formik initialValues={initialValues} onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)} validationSchema={validation} validateOnChange>
            <EditInformationForm setEditActive={setEditActive} providerSettings={providerSettings}/>
          </Formik>
        ) : (
          <Flex gap='0.5rem' direction='column'>
            <Flex gap='0.5rem' align='center'>
              <H2 bold>{initialValues.name}</H2>
              {formatBusinessVerificationMatchNumberToIcon(businessVerificationComparison.name)}
            </Flex>
            
            <Flex gap='1rem' align='center' justify='space-between'>
              <StyledIconLabel>
                <MdOutlineBusinessCenter />
                <BodyText>{initialValues.type}</BodyText>
              </StyledIconLabel>
              { +initialValues.established ? (
                <StyledIconLabel>
                  <CiFlag1 />
                  <Flex gap='0.5rem' align='center'>
                    <BodyText>Established {initialValues.established}</BodyText>
                    {formatBusinessVerificationMatchNumberToIcon(businessVerificationComparison.established)}
                  </Flex>
                </StyledIconLabel>
              ) : false }
            </Flex>
            <StyledIconLabel>
              <GrMapLocation />
              <Flex gap='0.5rem' align='center'>
                <BodyText>{fullAddress}</BodyText>
                {formatBusinessVerificationMatchNumberToIcon(businessVerificationComparison.address)}
              </Flex>
            </StyledIconLabel>
            {billingAddress ? 
              <StyledIconLabel>
                <CiReceipt />
                <BodyText>Billing Address: {buildAddressLine(billingAddress)}</BodyText>
              </StyledIconLabel> : false}
            {shippingAddress ? 
              <StyledIconLabel>
                <MdOutlineLocalShipping />
                <BodyText>Shipping Address: {buildAddressLine(shippingAddress)}</BodyText>
              </StyledIconLabel> : false}
            {(initialValues.website && initialValues.website !== NO_WEBSITE_VALUE) ? 
              <StyledIconLabel>
                <CiGlobe />
                <StyledExternalLink
                  href={(!/^https?:\/\//i.test(initialValues.website)) ? `https://${initialValues.website}` : initialValues.website}
                  target='_blank'>
                  {initialValues.website}
                </StyledExternalLink>
              </StyledIconLabel>
            : false }
            {initialValues.phoneNumber ? 
              <StyledIconLabel>
                <CiPhone />
                <BodyText>{initialValues.phoneNumber}</BodyText>
              </StyledIconLabel>
            : false }
            <StyledDescription style={{ paddingLeft: '0.5rem' }}>{initialValues.description}</StyledDescription>
          </Flex>
        )}
      </StyledGrayBox>
    </SectionContainer>
  )
};

import styled from 'styled-components';

import { FlexColumn, FlexRowItemsCenter } from '@/components/designSystem/Layout';
import { BodyText, StyledExternalLinkUnderline } from '@/components/designSystem/Typography';
import { Card } from '@/components/designSystem/cards/Card';

// COMMON

export const StyledCustomQuestionCard = styled(Card)`
  display: flex;
  gap: 2rem;
`;

// INFORMATION

export const StyledCompanyInfoInformationColumn = styled(FlexColumn)<{ flexBasis?: string }>`
  flex-basis: ${({ flexBasis }) => flexBasis ? `${flexBasis}%` : 'none'};
`;

export const StyledDescription = styled(BodyText)`
  display: block;
  margin-top: 1rem;
`;

export const StyledIconLabel = styled(FlexRowItemsCenter)`
  gap: 0.25rem;
  & > :first-child {
    margin-right: 0.25rem;
    min-width: 1.5rem;
  }
  
  svg {
    color: #6E7072;
    height: 1.25rem;
  }
`;

export const StyledExternalLink = styled(StyledExternalLinkUnderline)`
  font-size: 15px;
  line-height: 24px;
`;

export const StyledInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`

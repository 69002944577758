import React, { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';

import { PrivateRoute } from '@/routes/Routes';

import { get } from 'lodash/fp';
import { roleFallback } from '@/utils/roleFallback';

import { useQuery } from '@apollo/react-hooks';
import { GET_PARENT_COMPANY } from '@/graphql/queries/getParentCompany';
import { IUserCompanyRole } from '@/types/companyUser';
import { TabbedNavContainer } from '@/containers/TabbedNavContainer';
import { CurrentUserContext } from '@/providers/CurrentUser';
import { CreditApplicationCustomizableImportFields } from './CustomizableImportFields';

export const InternalTemplate = () => {
  const [companyId] = useLocalStorage('company-id');

  const { data: parentCompany } = useQuery(GET_PARENT_COMPANY);

  const currentUserData = useContext(CurrentUserContext);

  const parentCompanyId = get('parentCompany.id', parentCompany);
  const currentCompanyId = get('currentCompany.id', currentUserData) || companyId;
  const userCompanyRoles = get('currentUser.userCompanyRole', currentUserData);
  // eslint-disable-next-line max-len
  const currentUserCompanyRole = roleFallback(
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === currentCompanyId),
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === parentCompanyId)
  )
  // const isAdmin = get('isAdmin', currentUserCompanyRole[0])
  const changeTemplate = get('role.permissions.changeTemplate', currentUserCompanyRole[0]) || false;

  let navigationOptions = [
    { title: 'Internal Fields', path: '/dashboard/templates/internal/custom', Component: CreditApplicationCustomizableImportFields },
  ];

  let to = '/dashboard/templates/internal/custom';
  
  if (changeTemplate) {
    navigationOptions = [...navigationOptions]
    to = '/dashboard/templates/internal/custom'
  }

  return (
    <TabbedNavContainer title='Internal Template' options={navigationOptions}>
      <Switch>
        <Redirect exact from='/dashboard/templates/internal/' to={to} />
        {
          navigationOptions.map(({ path, Component }) => <PrivateRoute key={path} path={path} exact component={Component} />)
        }
      </Switch>
    </TabbedNavContainer>
  );
};

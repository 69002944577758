import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { getValidationForCustomFields } from '@/utils/customFields';

import { ICustomField } from '@/types/customField';

export const internalInfoValidation = (
  customFields: ICustomField[],
) => Yup.object().shape({
  customFields: Yup.object().shape(getValidationForCustomFields(customFields)),
})

import React, { useState, useContext } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { ANSWER_CUSTOM_FIELDS_BY_CP } from '@/graphql/mutations/answerCustomFields';

import { Formik, Form } from 'formik';

import { get, getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { mapFormValuesToFieldAnswers } from '@/utils/customFields';

import { ICustomer } from '@/types/customer';
import { ICustomField, ICustomFieldValue } from '@/types/customField';

import { VisitorContext } from '@/providers/VisitorContext';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { internalInfoInitialValues } from './initialValues';
import { internalInfoValidation } from './validation';
import { CUSTOM_FIELD_TYPES } from '@/constants';
import { StyledButtonsContainer } from '../../../styled';
import { CustomField, CustomFieldAnswers } from '@/components/CustomField/CustomField';
import { Button } from '@/components/designSystem/buttons';
import { MutationFunction } from '@apollo/react-hooks';

import { LabeledValueRow } from '../../../LabeledValue';
import { SectionContainer } from '../../../SectionContainer';
import { StyledGrayBox } from '../../../styled';


interface IValues {
  einFederalTaxNumber: string,
  customFields: ICustomFieldValue,
}

interface IInternalCustomAnswerSectionProps {
  customer: ICustomer,
  refetch: () => void,
  providerUpdateCompany: MutationFunction,
  canSeeEditControl: boolean,
}

export const InternalCustomAnswerSection = ({
  customer, providerUpdateCompany, refetch, canSeeEditControl
}: IInternalCustomAnswerSectionProps) => {
  const [isEditActive, setEditActive] = useState(false);
  
  const visitorData = useContext(VisitorContext);
  const customFieldAnswers = getOr([], 'customFieldAnswers', customer);
  const settings = get('providerCompany.settings', customer);
  
  const customFields: ICustomField[] = getOr([], 'providerCompany.customFields', customer);
  const customFieldsForInternalInfoSection: ICustomField[] = customFields
    .filter(customField => (
      customField.type !== CUSTOM_FIELD_TYPES.CONTACT &&
      customField.type !== CUSTOM_FIELD_TYPES.FILE_UPLOAD &&
      customField.kind === "import"
    ));
  
  const [answerCustomFields, { loading }] = useMutation(ANSWER_CUSTOM_FIELDS_BY_CP);

  if (customFieldsForInternalInfoSection.length === 0) return null;

  const updateCustomFields = async (formValues: IValues) => {
    const customerId = get('id', customer);
    const customFieldAnswersValue = mapFormValuesToFieldAnswers(
      formValues.customFields,
      customFieldsForInternalInfoSection
    )
    const response = await answerCustomFields({
      variables: {
        customerId: customerId,
        visitorData: visitorData,
        customFieldAnswers: customFieldAnswersValue,
        applicationAttrs: {},
      },
    });

    const success = get('data.answerCustomFieldsByCp', response);
    if (success) {
      showToast({
        title: 'Saved',
        description: 'The changes to this application were successfully saved',
        type: toast.TYPE.SUCCESS,
      });
      setEditActive(false);
      refetch();
    }
  };
  
  return (
    <SectionContainer
      title='Internal Information'
      isEditEnabled={!isEditActive}
      canSeeEditControl={canSeeEditControl}
      editSection={() => setEditActive(true)}
      onCloseClick={() => setEditActive(false)}
    >
      <StyledGrayBox>
        {isEditActive && canSeeEditControl ? (
          <Formik
            validateOnChange
            initialValues={internalInfoInitialValues(customFieldsForInternalInfoSection, customFieldAnswers)}
            validationSchema={internalInfoValidation(customFieldsForInternalInfoSection)}
            onSubmit={(values) => handleSubmitWrapper(values, updateCustomFields)}
            enableReinitialize
          >
            <Form>
              {customFieldsForInternalInfoSection.map((customField) => (
                <CustomField
                  key={customField.id}
                  name={`customFields.${customField.id}`}
                  {...customField}
                  options={customField.options}
                  settings={settings}
                />
              ))}
              <StyledButtonsContainer>
                <Button onClick={() => setEditActive(false)} secondary>Cancel</Button>
                <Button type='submit' primary>Save</Button>
              </StyledButtonsContainer>
            </Form>
          </Formik>
        ) : (
          <>
            {/* {customFieldsForInternalInfoSection.map((item) => (
              <LabeledValueRow key={item.id} label={item.customField.label} value={item.text} />
            ))} */}
            
            {customFieldsForInternalInfoSection.map((customField) => (
              <CustomFieldAnswers
                key={customField.id}
                name={`customFields.${customField.id}`}
                {...customField}
                options={customField.options}
                customFieldAnswers={customFieldAnswers}
                settings={settings}
              />
            ))}
          </>
        )}
      </StyledGrayBox>
    </SectionContainer>
  )
};

import React, { Dispatch, SetStateAction } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { UPDATE_COMPANY_CONTACTS } from '@/graphql/mutations/updateCompanyContacts';

import { Formik } from 'formik';

import { getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ICustomer } from '@/types/customer';
import { IStakeholder } from '@/types/stakeholder';
import { ICustomField } from '@/types/customField';

import { CUSTOM_FIELD_TYPES } from '@/constants';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { ContactsSectionForm } from './ContactsSectionForm';
import { contactsInitialValues } from './initialValues';
import { contactsValidation } from './validation';
import { IContactValues } from './types';
import { mapFormValuesToVariables } from './functions';

interface IProps {
  stakeholders: IStakeholder[],
  customerData: ICustomer,
  setEditActive: Dispatch<SetStateAction<boolean>>,
  refetch: () => void,
}

export const ContactsSectionUpdateView = ({ stakeholders, customerData, setEditActive, refetch }: IProps) => {
  const providerCustomFields: ICustomField[] = getOr([], 'providerCompany.customFields', customerData);
  const providerCustomContactFields = providerCustomFields.filter((customField) => customField.type === CUSTOM_FIELD_TYPES.CONTACT);
  const [updateCompanyContacts] = useMutation(UPDATE_COMPANY_CONTACTS);

  const handleSubmit = async (values: IContactValues) => {
    const updatedCompanyContacts = mapFormValuesToVariables(values, stakeholders);

    updateCompanyContacts({ variables: {
      companyContact: updatedCompanyContacts
    } })
      .then(() => {
        showToast({
          title: 'Information Successfully Updated!',
          description: 'Your customer contact information successfully updated.',
          type: toast.TYPE.SUCCESS,
        });
        setEditActive(false);
        refetch();
      })
      .catch((e) => {
        showToast({
          title: 'Error',
          description: 'Information wasn\'t updated',
          type: toast.TYPE.ERROR,
        });
        setEditActive(false);
      });
  };

  return (
    <Formik
      initialValues={contactsInitialValues(stakeholders)}
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      validationSchema={contactsValidation(stakeholders, providerCustomContactFields)}
      validateOnChange
    >
      <ContactsSectionForm
        stakeholders={stakeholders}
        providerCustomContactFields={providerCustomContactFields}
        customerData={customerData}
        setEditActive={setEditActive} />
    </Formik>
  )
};
